<template>
  <div>
    <LoadingSpinner v-model="loading" />

    <v-container v-if="hasProduct">
      <v-sheet class="my-3">
        <v-row>

          <v-col cols="12" lg="5">
            <ProductGallery
              :images="product.images"
            />
          </v-col>

          <v-col cols="12" lg="7">
            <v-card-text>
              <v-card-text>
                <v-btn color="secondary" outlined @click="backToPreviousPage">
                  <v-icon class="mr-3">mdi-arrow-left-circle</v-icon>
                  Back
                </v-btn>
              </v-card-text>
              <div class="product-details-page">
                <div class="product-details-page__content">
                  <h2 class="product-details-page__content--title">{{ product.name }}</h2>
                  <p v-if="product.categories" class="product-details-page__content--category">
                    <span v-for="(category, index) in product.categories" :key="'category'+index">
                      {{ category.name }} <span v-if="(index+1) !== product.categories.length"> || </span>
                    </span>
                  </p>
                  <p class="product-details-page__content--merchant">
                    <router-link
                      :to="`/vendor/${shopSlug}`"
                      class="text-decoration-none secondary--text"
                    >
                      {{ `Visit ${shopName} store` }}
                    </router-link>
                  </p>
                  <v-divider />

                  <!--<div class="product-details-page__content&#45;&#45;review">-->
                  <!--  <v-rating-->
                  <!--    :value="parseInt(product.rating)"-->
                  <!--    color="yellow darken-3"-->
                  <!--    empty-icon="$ratingFull"-->
                  <!--    half-increments-->
                  <!--    readonly-->
                  <!--  />-->
                  <!--</div>-->
                  <!--<v-divider></v-divider>-->

                  <div class="product-details-page__content--price">
                    <span>{{ product.price | currency }} </span>
                    <span v-if="warrantyInformation"> ({{ `${warrantyInformation.value} ${warrantyInformation.value_type} warranty`  }})</span>
                  </div>
                  <div class="product-details-page__content--color">
                    <div
                      class="product-details-page__content--color__content"
                      style="background-color: #006AFF"
                    />
                  </div>
                  <div class="text-h5"> {{ product.stock }} Item in stock </div>
                  <div class="product-details-page__content--quantity d-flex align-center">
                    <v-text-field

                      v-model.number="quantityCounter"
                      :error="quantityCounter < 1 || quantityCounter > product.stock"
                      :error-messages="(quantityCounter < 1 || quantityCounter > product.stock) ? 'Invalid product quantity' : ''"
                      append-outer-icon="mdi-plus"
                      color="secondary"
                      prepend-icon="mdi-minus"
                      style="max-width: 100px;"
                      @click:append-outer="increment"
                      @click:prepend="decrement"
                    />
                    <v-btn color="secondary" @click="handleAddToCart(product)">
                      <v-icon left>mdi-cart</v-icon>
                      Add To Cart
                    </v-btn>
                    <v-btn color="secondary" outlined @click="handleBuyNow(product)">
                      <v-icon left>mdi-cart-arrow-right</v-icon>
                      Buy now
                    </v-btn>
                  </div>

                  <div class="product-details-page__content--action mt-2">
                    <v-row>
                      <div class="medium mb-2">Have any inquery?</div>
                    </v-row>
                    <v-row>
                      <a :href="`mailto:${product.shop.email}`">
                        <v-btn color="info" outlined>
                          <v-icon left>mdi-email</v-icon>
                          Contact Seller
                        </v-btn>
                      </a>
                    </v-row>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-col>

        </v-row>
      </v-sheet>

      <v-sheet class="mt-5 mb-3">

        <v-tabs v-model="productDetailsTab" background-color="blue-grey lighten-5" color="secondary" left>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#description"> Description</v-tab>
          <v-tab :disabled="(!product && !product.options.length)" href="#specification"> Specification</v-tab>
          <v-tab :disabled="(!product && !product.attributes.length)" href="#more"> More </v-tab>
          <!--<v-tab href="#shipment"> Shipment info</v-tab>-->
          <!--<v-tab href="#review"> Review</v-tab>-->
        </v-tabs>

        <v-tabs-items v-model="productDetailsTab">

          <v-tab-item value="description">
            <v-card-text class="product-description">
              <div class="html-content" v-html="product.description" />
            </v-card-text>
          </v-tab-item>

          <v-tab-item v-if="product && product.options" value="specification">
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-simple-table>
                    <template v-slot:default>

                      <tbody>
                      <tr v-for="(item, index) in product.options" :key="'options' + index">
                        <td> {{ item.name }}</td>
                        <td> {{ item.value }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
          <v-tab-item v-if="product && product.attributes" value="more">
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-simple-table>
                    <template v-slot:default>

                      <tbody>
                      <tr v-for="(item, index) in product.attributes" :key="'options' + index">
                        <td> {{ item.name }}</td>
                        <td> {{ item.value }}</td>
                        <td> {{ item.value_type }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
          <!--<v-tab-item value="shipment">-->
          <!--  <v-card-text>-->
          <!--    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et-->
          <!--    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex-->
          <!--    ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat-->
          <!--    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit-->
          <!--    anim id est laborum.-->
          <!--  </v-card-text>-->
          <!--</v-tab-item>-->

          <!--<v-tab-item value="review">-->
          <!--  <v-card-text>-->
          <!--    <div class="product-details-page__review">-->
          <!--      <div class="product-details-page__review&#45;&#45;content d-flex flex-column">-->
          <!--        <div class="product-details-page__review&#45;&#45;content&#45;&#45;rating">-->
          <!--          <div class="text-h3 font-weight-bold mb-3 mt-3">{{ feedback.count }}/ 5.00</div>-->
          <!--          <v-rating-->
          <!--            :value="parseInt(feedback.count)"-->
          <!--            background-color="grey lighten-2" color="yellow accent-4"-->
          <!--            dense-->
          <!--            half-increments-->
          <!--            readonly-->
          <!--            size="48"-->
          <!--          >-->
          <!--          </v-rating>-->
          <!--          <div class="text-h6 mb-3 mt-3">{{ feedback.count }} Ratings</div>-->
          <!--        </div>-->
          <!--        <v-divider class="nt-3 mb-3"></v-divider>-->
          <!--        <div-->
          <!--          v-for="(feedback, index) in feedback.feedback"-->
          <!--          :key="'feedback'+index"-->
          <!--          class="product-details-page__review&#45;&#45;content&#45;&#45;feedback d-flex v-card__text align-center"-->
          <!--        >-->
          <!--          <v-avatar color="indigo" size="48">-->
          <!--            <span class="white&#45;&#45;text text-h6">JD</span>-->
          <!--          </v-avatar>-->
          <!--          <div class="feedback ml-4">-->
          <!--            <div class="feedback-user-name text-h6">{{ feedback.user.full_name }}</div>-->
          <!--            <v-rating :value="parseInt(feedback.rating)" background-color="grey" color="yellow accent-4"-->
          <!--                      dense half-increments readonly size="18"-->
          <!--            ></v-rating>-->
          <!--            <div class="review-user-text">{{ feedback.review }}</div>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--      </div>-->
          <!--    </div>-->
          <!--  </v-card-text>-->
          <!--</v-tab-item>-->

        </v-tabs-items>
      </v-sheet>

    </v-container>
  </div>
</template>

<script>
import './ProductsDetails.scss';
import ProductGallery from '@/components/Product/ProductGallery';
import { mapGetters, mapActions } from 'vuex';

import { isEmpty } from 'lodash';
import LoadingSpinner from '@/components/Utils/LoadingSpinner';
import { addToRecentViewList, storeHelper } from '@/utils/helper';
import { CART_ADD, cartNameSpace } from '@/store/modules/cart/types';

export default {

  name: 'ProductsDetails',

  components: { LoadingSpinner, ProductGallery },

  data: () => ({
    quantityCounter: 1,
    productDetailsTab: ''
  }),

  computed: {

    ...mapGetters({
      product: 'product/product',
      loading: 'product/loading'
    }),

    warrantyInformation(){
      const product = this.product;

      if(!product.attributes) {
        return null
      }

      if(!product.attributes.length) {
        return null
      }
      //return product.attributes;

      const warranty = product.attributes.find(attribute => attribute.name === 'warranty');
      return warranty;
    },

    currentRoute() {
      return this.$route.params;
    },

    hasProduct() {

      return !isEmpty(this.product);

    },

    shopName() {
      return this.product?.shop?.name;
    },

    shopSlug() {
      return this.product?.shop?.slug;
    },

    feedback() {
      return {
        score: this.product?.rating,
        feedback: this.product?.feedback,
        count: this.product?.feedback?.length
      };
    }
  },

  watch: {
    currentRoute: {
      handler() {
        this.fetchProduct();
      },
      immediate: true
    }
  },

  async mounted() {
    await this.fetchProduct();
    addToRecentViewList(this.product);
  },

  methods: {
    ...mapActions({
      getProduct: 'product/getProduct',
      addToCart: storeHelper(cartNameSpace, CART_ADD)
    }),

    handleAddToCart(item){
      const data = {
        ...item,
        quantity: this.quantityCounter
      }

      return this.addToCart(data);
    },

    async fetchProduct() {
      let productSlug = this.$route.params.product;
      let vendorSLug = this.$route.params.vendor;
      await this.getProduct({ productSlug, vendorSLug });
    },

    handleBuyNow(product) {
      this.handleAddToCart(product);
      this.$router.push({ name: 'Cart' });
    },

    increment() {
      if (this.quantityCounter >= this.product.stock) return;
      this.quantityCounter = parseInt(this.quantityCounter, 10) + 1;
    },

    decrement() {
      if (this.quantityCounter <= 1) return;
      return this.quantityCounter = parseInt(this.quantityCounter, 10) - 1;
    }

  }
};
</script>

<style lang='scss'>
.product-details-page {

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    &--quantity {
      flex: 1 2 auto;
      column-gap: 30px;
    }
  }
}

.product-description {
  white-space: pre !important;
}
</style>
