<template>
  <div class="gallery-container">
    <div v-if="previewImg" class="gallery-container__preview">
      <div class="gallery-container__preview--content">
        <!--<img-->
        <!--  :src="previewImg.url"-->
        <!--  :alt="previewImg.alt"-->
        <!--/>-->

        <InnerImageZoom
          :src="previewImg.url"
        />

      </div>
    </div>
    <div class="gallery-container__thumb">
      <div class="gallery-container__thumb--content cursor-pointer">
        <img
          v-for="(image, index) in images"
          :key="getRandomKey(index)"
          :alt="image.alt"
          :data="getRandomKey(index)"
          :src="image.url"
          @click="updateImage(image)"
        >
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import InnerImageZoom from 'vue-inner-image-zoom';
import { isEmpty } from 'lodash';
import { API_BASE_URL } from '@/utils/helper';

export default {
  name: 'ProductGallery',
  components: { InnerImageZoom },
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    previewImg: null,
    defaultImage: {
      url: `${API_BASE_URL}/storage/no-images.jpg`
    }
  }),

  computed: {
    productImages(){
      if(!this.images?.length) return [this.defaultImage];

      return this.images;
    }
  },

  mounted() {
    this.updateImage(this.productImages[0])
  },

  watch: {
    images: {

      immediate: true,
      deep: true,

      handler(nv){
        if(isEmpty(nv)) {
          this.updateImage(this.productImages[0]);
        }

        this.updateImage(this.productImages[0]);
      }
    }
  },

  methods: {
    getRandomKey(index) {
      return Math.random().toString(36).substring(2) + index;
    },
    updateImage(img) {
      this.previewImg = img;
    }
  },
};
</script>

<style lang="scss">

.gallery-container {

  &__preview {
    &--content {
      //border: 1px solid rgba(128, 128, 128, 0.35);
      border-radius: 5px;
      margin-bottom: 20px;
    }
  }

  &__thumb {
    width: 100%;

    &--content {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 80px;

      img {
        border: 1px solid rgba(128, 128, 128, 0.35);
        border-radius: 3px;
        padding: 5px;
        height: 80px;
        width: 80px;
      }
    }
  }
}
.img-fluid {
  width:100% !important;
  max-width: 100%;
  height:auto !important;
  max-height: 400px;
}
</style>
